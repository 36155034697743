import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import * as React from 'react';
import styled from 'styled-components';
import { Logo as LogoComponent } from '../components/Logo';
import { Seo } from '../components/Seo';
import { SideElements } from '../components/SideElements/SideElements';
import { SubpageLayout } from '../components/SubpageLayout';

const H2 = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Logo = styled(LogoComponent)`
  height: 1.25rem;
  min-width: fit-content;
  margin-bottom: 0.1rem;
`;

const TextBold = styled.span`
  font-weight: bold;
`;

const Ul = styled.ul`
  & > li {
    padding-bottom: 0.5rem;
  }
`;

const EuFunds = () => {
  const { spectrum } = useStaticQuery(graphql`
      query {
          spectrum: file(relativePath: { eq: "spectrum.jpg" }) {
              childImageSharp {
                  fluid(fit: FILL, maxHeight: 600 ) {
                        ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  return (
    <SubpageLayout
      title="Dofinansowanie"
    >
      <Seo
        title="Dofinansowanie"
      />
      <SideElements/>
      <H2 className="uppercase text-2xl mb-4">
        <Logo
          className="mr-2"
        />
        <span
          style={{ textAlign: "center" }}
        >
            jest grantobiorcą programu Spektrum
          </span>
      </H2>
      <Img
        fluid={spectrum.childImageSharp.fluid}
        fadeIn={false}
        alt="SPECTRUM Europejskiego Funduszu Rozwoju Regionalnego"
        loading='lazy'
      />
      <div
        className="pt-4 content-color"
      >
        <p>
          <TextBold>FAKTURUJ.TO</TextBold> realizuje projekt dofinansowany z Europejskiego Funduszu Rozwoju Regionalnego
          w ramach
          programu „SPEKTRUM. Pomorski System Usług Doradczych”. Program jest dofinansowany ze środków Unii Europejskiej
          z
          Regionalnego Programu Operacyjnego Województwa Pomorskiego na lata 2014-2020.
        </p>
        <Ul className="py-6">
          <li>Całkowity koszt projektu: <TextBold>42 804 PLN</TextBold></li>
          <li>Wydatki kwalifikowane: <TextBold>34 800 PLN</TextBold></li>
          <li>Dofinansowanie ze środków europejskich: <TextBold>17 400 PLN</TextBold></li>
        </Ul>
        <p>
          Celem realizacji projektu jest rozwój przedsiębiorstwa.
        </p>
      </div>
    </SubpageLayout>
  );
};

export default EuFunds;
